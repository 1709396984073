/*!
 * Name: Atlas
 * Author: Zengenti Ltd
 * Author URI: http://zengenti.com
 * Description: A modern front-end framework
 * Version: 0.0.1
 */

var app = (function ($) {
    'use strict';

    // private alias to settings
    var s;

    return {
        settings: function() {
            this.$window        = $(window);

            this.hello			= 'Hello World';  // Example var
        },

        init: function() {
            s = new this.settings();

            this.helloWorld();
            this.responsiveMenu();
            this.scrollToTop();

            this.bindUIActions();       // UI Actions (click, focus etc)
            this.bindWindowActions();   // Window actions (scrolling, resize etc)
        },

        helloWorld: function() {  		// Example function
            console.log(s.hello);  
        },

        
        responsiveMenu: function() {
          $('.mobile-menu').on('click', function (e) {
            e.preventDefault();
            $('.site-navigation').show(700);
          });
          $('.mobile-menu-close').on('click', function (e) {
            e.preventDefault();
            $('.site-navigation').hide(700);
          });
        },
        



        scrollToTop: function() {
            if ($('#back-to-top').length) {
                var scrollTrigger = 400, // px
                backToTop = function () {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > scrollTrigger) {
                        $('#back-to-top').addClass('show');
                    } else {
                        $('#back-to-top').removeClass('show');
                    }
                };
                backToTop();
                $(window).on('scroll', function () {
                    backToTop();
                });
                $('#back-to-top').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                        scrollTop: 0
                    }, 700);
                });
            }
        },

        bindUIActions: function() {
            
        },

        bindWindowActions: function() {
            
        }
    
    };
})(jQuery);

jQuery(document).ready(function() {
    app.init();
});